import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GroupTableStudents from "./GroupTableStudents";
import GroupTableSubjects from "./GroupTableSubjects";
import GroupDocuments from "./GroupDocuments";
import GroupTableTesoreria from "./GroupTableTesoreria";
import GroupTableAttachments from "./GroupTableAttachments";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const GroupDetailsTabsContainer = props => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [studentsLength, setStudentsLength] = useState([]);

    useEffect(() => {
        setStudentsLength(props.group?.groupStudents?.length);
    }, [props.group]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tableStudentsCallback = (students) => {
        setStudentsLength(students);
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Estudiantes">
                    <Tab label={studentsLength ? `Estudiantes (${studentsLength})` : "Estudiantes"} {...a11yProps(0)} />
                    <Tab label="Materias" {...a11yProps(1)} />
                    <Tab label="Certificados" {...a11yProps(2)} />
                    <Tab label="Tesorería" {...a11yProps(3)} />
                    <Tab label="Archivos" {...a11yProps(4)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <GroupTableStudents
                    group={props.group}
                    students={props.students}
                    backdropCallback={props.backdropCallback}
                    callback={tableStudentsCallback}
                    alertCallback={props.alertCallback}
                    history={props.history}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <GroupTableSubjects group={props.group} history={props.history}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <GroupDocuments group={props.group}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <GroupTableTesoreria groupPaymentsSummary={props.groupPaymentsSummary}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <GroupTableAttachments group={props.group} />
            </TabPanel>
        </div>
    );
};

export default GroupDetailsTabsContainer;
