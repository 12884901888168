import React, {useEffect, useState} from "react";
import MaterialTable, {MTableToolbar} from "material-table";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {LOCALIZATION} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {API} from "aws-amplify";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import {MAJORS_COLUMN_DEFINITION_FAST} from "../../components/Material-TableCustomization/ColumnsDefinition/MajorsColumnDefinition";

export default function Majors(props) {
    const [selectedMajor, setSelectedMajor] = useState("INDUSTRIAL-2017");
    const [topicsForCurrentMajor, setTopicsForCurrentMajor] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const availableMajors = [
        "AMBIENTAL-2024",
        "ENFERMERIA-2023",
        "ENFERMERIA_SEMESTRAL-2023",
        "PSICOPEDAGOGIA-2023",
        "DERECHO-2023",
        "ADMINISTRACION-2023",
        "INDUSTRIAL-2023",
        "PETROLERA-2023",
        "MAESTRIAPETROLERA-2023",
        "MAESTRIAEDUCACION-2023",
        "ENFERMERIA-2017",
        "PSICOPEDAGOGIA-2017",
        "DERECHO-2017",
        "ADMINISTRACION-2017",
        "AMBIENTAL-2017",
        "INDUSTRIAL-2017",
        "PETROLERA-2017",
        "MAESTRIAPETROLERA-2017",
        "MAESTRIAEDUCACION-2017",
        "ENFERMERIA-2013",
        "PSICOPEDAGOGIA-2013",
        "DERECHO-2013",
        "ADMINISTRACION-2013",
        "AMBIENTAL-2013",
        "INDUSTRIAL-2013",
        "PETROLERA-2013",
        "MAESTRIAPETROLERA-2013",
        "MAESTRIAEDUCACION-2013",
        "LOGISTICA-2023"
    ]

    const loadTopicsForMajorsApi = (major) => {
        return API.get('misServiciosEscolares', `/topics?major=${major || selectedMajor}`);
    };

    const loadTopicsForMajors = async (major) => {
        setIsLoading(true);
        try {
            const response = await loadTopicsForMajorsApi(major);
            setTopicsForCurrentMajor(response);
        } catch (e) {
            alert(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleChange = e => {
        setSelectedMajor(e.target.value);
        loadTopicsForMajors(e.target.value);
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadTopicsForMajors();
    }, [props.isAuthenticated]);

    const updateTopicForCurrentMajor = topicToBeUpdated => {
        console.log('topicToBeUpdated', topicToBeUpdated);
        return API.put("misServiciosEscolares", `/topics?majorPlan=${selectedMajor}`, {
            body: topicToBeUpdated
        });
    }

    const handleOnRowUpdate = async (newData, oldData, resolve, reject) => {
        if (newData === oldData) {
            reject();
            return;
        }
        try {
            console.log('newData', newData);
            await updateTopicForCurrentMajor(newData);
            await loadTopicsForMajors();
        } catch (e) {
            alert("No se pudo actualizar la materia");
        } finally {
            resolve();
        }
    };

    const deleteTopicForCurrentMajorApi = topicId => {
        return API.del("misServiciosEscolares", `/majors/${selectedMajor}/topics/${topicId}`);
    }

    const handleOnRowDelete = async (topicToBeDeleted, resolve, reject) => {
        if (!topicToBeDeleted) {
            reject();
            return;
        }
        try {
            await deleteTopicForCurrentMajorApi(topicToBeDeleted.topicId);
            await loadTopicsForMajors();
        } catch (e) {
            alert("No se pudo actualizar la materia");
        } finally {
            resolve();
        }
    };

    const addTopicForCurrentMajorApi = topic => {
        return API.post("misServiciosEscolares", `/majors/${selectedMajor}/topics/`, {
            body: {
                ...topic,
                majorPlan: selectedMajor
            }
        });
    }

    // const handleOnRowAdd = async (newTopic, resolve, reject) => {
    //     if (!newTopic) {
    //         reject();
    //         return;
    //     }
    //     try {
    //         await addTopicForCurrentMajorApi(newTopic);
    //         await loadTopicsForMajors();
    //     } catch (e) {
    //         alert("No se pudo actualizar la materia");
    //     } finally {
    //         resolve();
    //     }
    // };


    return (
        <MaterialTable
            title={`Materias de ${selectedMajor}`}
            data={topicsForCurrentMajor}
            columns={MAJORS_COLUMN_DEFINITION_FAST(props)}
            isLoading={isLoading}
            options={{
                search: true,
                paging: false,
                showTitle: true,
            }}
            editable={{
                onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                    handleOnRowUpdate(newData, oldData, resolve, reject);
                }),
                onRowDelete: (newData) => new Promise((resolve, reject) => {
                    handleOnRowDelete(newData, resolve, reject);
                })
            }}
            components={{
                ...REMOVE_DROP_SHADOW,
                Toolbar: (props) => (
                    <div style={{padding: '15px 0', left: '25px'}}>
                        <MTableToolbar {...props} />
                        <FormControl style={{width: '300px'}}>
                            <InputLabel id="select-major-plan-label">Carrera-Plan</InputLabel>
                            <Select
                                labelId="select-major-plan-label"
                                id="select-major-plan"
                                value={selectedMajor}
                                onChange={handleChange}
                            >
                                {
                                    availableMajors.map(o => (
                                        <MenuItem value={o} key={o}>{o}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>
                ),
            }}
            localization={LOCALIZATION("materia", "materias")}
            icons={ICONS_OVERRIDE_SECONDARY_COLOR}
        />
    );
}
