import React, {Fragment, useState} from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import "./ModalNewStudent.css"
import {API} from "aws-amplify";
import {makeStyles} from "@material-ui/core/styles";
import {calculateCurrentCuatrimestreYYYYMMDD} from "../CommonUtilities/UtilityFunctions";
import {Tooltip} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    FormControl: {
        width: 550
    },
}));

const mexicanStates = [
    { value: "NO MEXICANO", label: "Nacido fuera del país" },
    { value: "Aguascalientes", label: "Aguascalientes" },
    { value: "Baja California", label: "Baja California" },
    { value: "Baja California Sur", label: "Baja California Sur" },
    { value: "Campeche", label: "Campeche" },
    { value: "Chiapas", label: "Chiapas" },
    { value: "Ciudad de México", label: "Ciudad de México" },
    { value: "Chihuahua", label: "Chihuahua" },
    { value: "Coahuila", label: "Coahuila" },
    { value: "Colima", label: "Colima" },
    { value: "Durango", label: "Durango" },
    { value: "Guanajuato", label: "Guanajuato" },
    { value: "Guerrero", label: "Guerrero" },
    { value: "Hidalgo", label: "Hidalgo" },
    { value: "Jalisco", label: "Jalisco" },
    { value: "Estado de México", label: "Estado de México" },
    { value: "Michoacán", label: "Michoacán" },
    { value: "Morelos", label: "Morelos" },
    { value: "Nayarit", label: "Nayarit" },
    { value: "Nuevo León", label: "Nuevo León" },
    { value: "Oaxaca", label: "Oaxaca" },
    { value: "Puebla", label: "Puebla" },
    { value: "Querétaro", label: "Querétaro" },
    { value: "Quintana Roo", label: "Quintana Roo" },
    { value: "San Luis Potosí", label: "San Luis Potosí" },
    { value: "Sinaloa", label: "Sinaloa" },
    { value: "Sonora", label: "Sonora" },
    { value: "Tabasco", label: "Tabasco" },
    { value: "Tamaulipas", label: "Tamaulipas" },
    { value: "Tlaxcala", label: "Tlaxcala" },
    { value: "Veracruz", label: "Veracruz" },
    { value: "Yucatán", label: "Yucatán" },
    { value: "Zacatecas", label: "Zacatecas" }
];

const generateCuatrimestres = (startYear = 2017) => {
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 1; // Include next year
    const cycles = [];

    for (let year = startYear; year <= endYear; year++) {
        cycles.push({ value: `${year}-01-01`, display: `${year}-01` });
        cycles.push({ value: `${year}-05-01`, display: `${year}-02` });
        cycles.push({ value: `${year}-09-01`, display: `${year}-03` });
    }

    return cycles;
};

export default function FormDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const defaultStudentState = {
        status: 'ACTIVO',
        plan: '2017',
        startingDate: calculateCurrentCuatrimestreYYYYMMDD()
    };
    const [student, setStudent] = useState(defaultStudentState);

    const createStudentApi = () => {
        student.majorType = student.major.toUpperCase().includes("MAESTRIA") ? "MAESTRIA" : "LICENCIATURA";
        return API.post("misServiciosEscolares", "/students", {
            body: student
        });
    };

    const handleChange = nameValue => ({target: {value}}) => {
        setStudent({...student, [nameValue]: value});
    };

    const isFormComplete = () => {
        return Boolean( student.firstName && student.lastNameFather && student.lastNameMother && student.major &&
            student.plan && student.status && student.startingDate !== "");
    }

    const handleSubmit = async () => {
        setOpen(false);
        props.callback(true, "PENDING");
        try {
            let response = await createStudentApi();
            clearStudentForm();
            props.callback(false, response);
        } catch (e) {
            console.error(e);
            props.callback(false, "");
        }
    };

    const clearStudentForm = () => {
        setOpen(false);
        setStudent(defaultStudentState);
    }

    return (
        <div className="ModalNewStudent">
            <Fragment>
                <Dialog
                    open={open}
                    onClose={clearStudentForm}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Crear Estudiante</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus={true}
                            required
                            fullWidth
                            margin="dense"
                            label="Nombre(s) del Alumno (requerido)"
                            value={student.firstName || ''}
                            onChange={handleChange('firstName')}
                            className={classes.FormControl}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="Apellido Paterno (requerido)"
                            value={student.lastNameFather || ''}
                            onChange={handleChange('lastNameFather')}
                            className={classes.FormControl}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="Apellido Materno (requerido)"
                            value={student.lastNameMother || ''}
                            onChange={handleChange('lastNameMother')}
                            className={classes.FormControl}
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel id="major-label">
                                Carrera (requerido)*
                            </InputLabel>
                            <Select
                                labelId="major-select-label"
                                id="major-select"
                                value={student.major || ''}
                                onChange={handleChange('major')}
                                required
                                className={classes.FormControl}
                            >
                                // TODO: Refactor with UtilityFunctions majors()
                                <MenuItem value={"ENFERMERIA"}>Licenciatura en Enfermería</MenuItem>
                                <MenuItem value={"ENFERMERIA_SEMESTRAL"}>Licenciatura en Enfermería (Semestral)</MenuItem>
                                <MenuItem value={"PSICOPEDAGOGIA"}>Licenciatura en Psicopedagogía</MenuItem>
                                <MenuItem value={"DERECHO"}>Licenciatura en Derecho y Juicios Orales</MenuItem>
                                <MenuItem value={"ADMINISTRACION"}>Licenciatura en Administracion de Empresas</MenuItem>
                                <MenuItem value={"AMBIENTAL"}>Licenciatura en Ingeniería Ambiental y Desarrollo Sustentable</MenuItem>
                                <MenuItem value={"INDUSTRIAL"}>Licenciatura en Ingeniería Industrial</MenuItem>
                                <MenuItem value={"LOGISTICA"}>Ingeniería en Logística y Seguridad del Sector Energético</MenuItem>
                                <MenuItem value={"PETROLERA"}>Licenciatura en Ingeniería Petrolera</MenuItem>
                                <MenuItem value={"MAESTRIAPETROLERA"}>Maesría Petrolera</MenuItem>
                                <MenuItem value={"MAESTRIAEDUCACION"}>Maestría en Educación</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="status-input-label">
                                Estatus (requerido)*
                            </InputLabel>
                            <Select
                                labelId="status-select-label"
                                id="status-select"
                                value={student.status || ''}
                                onChange={handleChange('status')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"ACTIVO"}>Activo</MenuItem>
                                <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
                                <MenuItem value={"EGRESO"}>Egresado</MenuItem>
                                <MenuItem value={"TITULADO"}>Titulado</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="plan-input">
                                Plan (requerido)*
                            </InputLabel>
                            <Select
                                labelId="plan-select-label"
                                id="plan-select"
                                value={student.plan || ''}
                                onChange={handleChange('plan')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"2024"}>2024</MenuItem>
                                <MenuItem value={"2023"}>2023</MenuItem>
                                <MenuItem value={"2017"}>2017</MenuItem>
                                <MenuItem value={"2013"}>2013</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="startingDate-input">Primer Cuatrimestre (requerido)*</InputLabel>
                            <Select
                                labelId="startingDate-select-label"
                                id="startingDate-select"
                                value={student.startingDate || ''}
                                onChange={handleChange('startingDate')}
                                required
                                className={classes.FormControl}
                            >
                                {generateCuatrimestres().map(({ value, display }) => (
                                    <MenuItem key={value} value={value}>{display}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mode-input">
                                Modalidad
                            </InputLabel>
                            <Select
                                labelId="mode-select-label"
                                id="mode-select"
                                value={student.mode || ''}
                                onChange={handleChange('mode')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"ESCOLARIZADO"}>Escolarizado</MenuItem>
                                <MenuItem value={"SABADOS"}>Sábados</MenuItem>
                                <MenuItem value={"DOMINGOS"}>Domingos</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Matrícula"
                            value={student.studentId || ''}
                            onChange={handleChange('studentId')}
                            className={classes.FormControl}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            label="CURP"
                            value={student.curp || ''}
                            onChange={handleChange('curp')}
                            className={classes.FormControl}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="Escuela de Procedencia (requerido)"
                            value={student.formerSchool || ''}
                            onChange={handleChange('formerSchool')}
                            className={classes.FormControl}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Entidad de su escuela de procedencia"
                            value={student.formerSchoolEntity || ''}
                            onChange={handleChange('formerSchoolEntity')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 25 }}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Teléfono"
                            value={student.phone || ''}
                            onChange={handleChange('phone')}
                            className={classes.FormControl}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Teléfono Referencia"
                            value={student.referencePhone || ''}
                            onChange={handleChange('referencePhone')}
                            className={classes.FormControl}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Email"
                            type="email"
                            value={student.email || ''}
                            onChange={handleChange('email')}
                            className={classes.FormControl}
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Grupo
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={student.group || ''}
                                onChange={handleChange('group')}
                                className={classes.FormControl}
                            >
                                <MenuItem value={"A"}>A</MenuItem>
                                <MenuItem value={"B"}>B</MenuItem>
                                <MenuItem value={"C"}>C</MenuItem>
                                <MenuItem value={"D"}>D</MenuItem>
                                <MenuItem value={"E"}>E</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel>
                                Nacionalidad
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={student.nationality || ''}
                                onChange={handleChange('nationality')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value="MEXICO">Mexicano</MenuItem>
                                <MenuItem value="ESTADOS UNIDOS">Estadounidense</MenuItem>
                                <MenuItem value="GUATEMALA">Guatemalteco</MenuItem>
                                <MenuItem value="BELICE">Beliceño</MenuItem>
                                <MenuItem value="HONDURAS">Hondureño</MenuItem>
                                <MenuItem value="EL SALVADOR">Salvadoreño</MenuItem>
                                <MenuItem value="OTRA NACIONALIDAD">Otro</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="state-of-birth-label">Lugar de Nacimiento</InputLabel>
                            <Select
                                labelId="state-of-birth-label"
                                id="state-of-birth"
                                value={student.stateOfBirth || ''}
                                onChange={handleChange('stateOfBirth')}
                                required
                                className={classes.FormControl}
                            >
                                {mexicanStates.map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Dirección actual"
                            value={student.address || ''}
                            onChange={handleChange('address')}
                            className={classes.FormControl}
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel id="living-state-label">Estado de residencia</InputLabel>
                            <Select
                                labelId="living-state-label"
                                id="living-state"
                                value={student.livingState || ''}
                                onChange={handleChange('livingState')}
                                required
                                className={classes.FormControl}
                            >
                                {mexicanStates
                                    .filter(state => state.value !== "NO MEXICANO") // Exclude non-Mexican option
                                    .map(({ value, label }) => (
                                        <MenuItem key={value} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Género
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={student.gender || ''}
                                onChange={handleChange('gender')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"H"}>Hombre</MenuItem>
                                <MenuItem value={"M"}>Mujer</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={clearStudentForm}
                            color="primary"
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            disabled={!isFormComplete()}
                        >
                            Crear estudiante
                        </Button>
                    </DialogActions>
                </Dialog>
                <Fab color="primary" aria-label="add" className={classes.fab}>
                    <Tooltip title="Crear Estudiante" aria-label="add">
                        <Fab color="primary" className={classes.fab}>
                            <AddIcon onClick={() => setOpen(true)}/>
                        </Fab>
                    </Tooltip>
                </Fab>
            </Fragment>
        </div>
    );

};
