import React, {Fragment, useState} from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {API} from "aws-amplify";
import {makeStyles} from "@material-ui/core/styles";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {calculateCurrentCuatrimestreYYYYMMDD} from "../../components/CommonUtilities/UtilityFunctions";

const useStyles = makeStyles({
    FormControl: {
        width: 550
    }
});

export default function Invitation(props) {
    const classes = useStyles();
    const defaultStudentState = {
        status: 'ACTIVO',
        plan: '2017',
        startingDate: calculateCurrentCuatrimestreYYYYMMDD()
    };
    const [student, setStudent] = useState(defaultStudentState);

    const createStudentApi = () => {
        student.majorType = student.major.toUpperCase().includes("MAESTRIA") ? "MAESTRIA" : "LICENCIATURA";
        return API.post("misServiciosEscolares", "/prospects", {
            body: student
        });
    };

    const handleChange = nameValue => ({target: {value}}) => {
        setStudent({...student, [nameValue]: value});
    };

    const isFormComplete = () => {
        return Boolean( student.firstName && student.lastNameFather && student.lastNameMother && student.major
            && student.plan && student.status && student.startingDate !== "" && student.cycle !== "");
    }

    const handleSubmit = async () => {
        try {
            await createStudentApi();
            // clearStudentForm();
        } catch (e) {
            console.error(e);
        }
    };

    const clearStudentForm = () => {
        setStudent(defaultStudentState);
    }

    function renderInvitation() {
        return (
            <div className="ModalNewStudent" style={{textAlign: 'center'}}>
                <h2>Pre Registro</h2>
                <Fragment>
                    <div>
                        <TextField
                            autoFocus={true}
                            required
                            fullWidth
                            margin="dense"
                            label="Nombre(s) (requerido) "
                            value={student.firstName || ''}
                            onChange={handleChange('firstName')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="Apellido Paterno (requerido)"
                            value={student.lastNameFather || ''}
                            onChange={handleChange('lastNameFather')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="Apellido Materno (requerido)"
                            value={student.lastNameMother || ''}
                            onChange={handleChange('lastNameMother')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="major-label">
                                Carrera (requerido)*
                            </InputLabel>
                            <Select
                                labelId="major-select-label"
                                id="major-select"
                                value={student.major || ''}
                                onChange={handleChange('major')}
                                required
                                className={classes.FormControl}
                            >
                                // TODO: Refactor with UtilityFunctions majors()
                                <MenuItem value={"ENFERMERIA"}>Licenciatura en Enfermería</MenuItem>
                                <MenuItem value={"ENFERMERIA_SEMESTRAL"}>Licenciatura en Enfermería</MenuItem>
                                <MenuItem value={"PSICOPEDAGOGIA"}>Licenciatura en Psicopedagogía</MenuItem>
                                <MenuItem value={"DERECHO"}>Licenciatura en Derecho y Juicios Orales</MenuItem>
                                <MenuItem value={"LOGISTICA"}>Licenciatura Ingeniería en Logística y Seguridad del Sector Energético</MenuItem>
                                <MenuItem value={"ADMINISTRACION"}>Licenciatura en Administracion de
                                    Empresas</MenuItem>
                                <MenuItem value={"AMBIENTAL"}>Licenciatura en Ingeniería Ambiental y Desarrollo
                                    Sustentable</MenuItem>
                                <MenuItem value={"INDUSTRIAL"}>Licenciatura en Ingeniería Industrial</MenuItem>
                                <MenuItem value={"PETROLERA"}>Licenciatura en Ingeniería Petrolera</MenuItem>
                                <MenuItem value={"MAESTRIA PETROLERA"}>Maesría Petrolera</MenuItem>
                                <MenuItem value={"MAESTRIA EN EDUCACION"}>Maestría en Educación</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="status-input-label">
                                Estatus (requerido)*
                            </InputLabel>
                            <Select
                                labelId="status-select-label"
                                id="status-select"
                                value={student.status || ''}
                                onChange={handleChange('status')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"ACTIVO"}>Activo</MenuItem>
                                <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
                                <MenuItem value={"EGRESO"}>Egresado</MenuItem>
                                <MenuItem value={"TITULADO"}>Titulado</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="plan-input">
                                Plan (requerido)*
                            </InputLabel>
                            <Select
                                labelId="plan-select-label"
                                id="plan-select"
                                value={student.plan || ''}
                                onChange={handleChange('plan')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"2024"}>2024</MenuItem>
                                <MenuItem value={"2023"}>2023</MenuItem>
                                <MenuItem value={"2017"}>2017</MenuItem>
                                <MenuItem value={"2013"}>2013</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="startingDate-input">
                                Fecha de inicio (requerido)*
                            </InputLabel>
                            <Select
                                labelId="startingDate-select-label"
                                id="startingDate-select"
                                value={student.cycle || ''}
                                onChange={handleChange('cycle')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"17-01"}>17-01</MenuItem>
                                <MenuItem value={"17-02"}>17-02</MenuItem>
                                <MenuItem value={"17-03"}>17-03</MenuItem>
                                <MenuItem value={"18-01"}>18-01</MenuItem>
                                <MenuItem value={"18-02"}>18-02</MenuItem>
                                <MenuItem value={"18-03"}>18-03</MenuItem>
                                <MenuItem value={"19-01"}>19-01</MenuItem>
                                <MenuItem value={"19-02"}>19-02</MenuItem>
                                <MenuItem value={"19-03"}>19-03</MenuItem>
                                <MenuItem value={"20-01"}>20-01</MenuItem>
                                <MenuItem value={"20-02"}>20-02</MenuItem>
                                <MenuItem value={"20-03"}>20-03</MenuItem>
                                <MenuItem value={"21-01"}>Enero 2021</MenuItem>
                                <MenuItem value={"21-02"}>Mayo 2021</MenuItem>
                                <MenuItem value={"21-03"}>Septiembre 2021</MenuItem>
                                <MenuItem value={"22-01"}>22-01</MenuItem>
                                <MenuItem value={"22-02"}>22-02</MenuItem>
                                <MenuItem value={"22-03"}>22-03</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mode-input">
                                Modalidad
                            </InputLabel>
                            <Select
                                labelId="mode-select-label"
                                id="mode-select"
                                value={student.mode || ''}
                                onChange={handleChange('mode')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"ESCOLARIZADO"}>Escolarizado</MenuItem>
                                <MenuItem value={"SABADOS"}>Sábados</MenuItem>
                                <MenuItem value={"DOMINGOS"}>Domingos</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Teléfono"
                            value={student.phone || ''}
                            onChange={handleChange('phone')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Teléfono Referencia"
                            value={student.referencePhone || ''}
                            onChange={handleChange('referencePhone')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Email"
                            type="email"
                            value={student.email || ''}
                            onChange={handleChange('email')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Grupo
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={student.group || ''}
                                onChange={handleChange('group')}
                                className={classes.FormControl}
                            >
                                <MenuItem value={"A"}>A</MenuItem>
                                <MenuItem value={"B"}>B</MenuItem>
                                <MenuItem value={"C"}>C</MenuItem>
                                <MenuItem value={"D"}>D</MenuItem>
                                <MenuItem value={"E"}>E</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="CURP"
                            value={student.curp || ''}
                            onChange={handleChange('curp')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel>
                                Nacionalidad
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={student.nationality || ''}
                                onChange={handleChange('nationality')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value="MEXICO">Mexicano</MenuItem>
                                <MenuItem value="ESTADOS UNIDOS">Estadounidense</MenuItem>
                                <MenuItem value="GUATEMALA">Guatemalteco</MenuItem>
                                <MenuItem value="BELICE">Beliceño</MenuItem>
                                <MenuItem value="HONDURAS">Hondureño</MenuItem>
                                <MenuItem value="EL SALVADOR">Salvadoreño</MenuItem>
                                <MenuItem value="OTRA NACIONALIDAD">Otro</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Lugar de Nacimiento
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={student.stateOfBirth || ''}
                                onChange={handleChange('stateOfBirth')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"NO MEXICANO"}>Nacido fuera del país</MenuItem>
                                <MenuItem value={"Aguascalientes"}>Aguascalientes</MenuItem>
                                <MenuItem value={"Baja California"}>Baja California</MenuItem>
                                <MenuItem value={"Baja California Sur"}>Baja California Sur</MenuItem>
                                <MenuItem value={"Campeche"}>Campeche</MenuItem>
                                <MenuItem value={"Chiapas"}>Chiapas</MenuItem>
                                <MenuItem value={"Ciudad de México"}>Ciudad de México</MenuItem>
                                <MenuItem value={"Chihuahua"}>Chihuahua</MenuItem>
                                <MenuItem value={"Coahuila"}>Coahuila</MenuItem>
                                <MenuItem value={"Colima"}>Colima</MenuItem>
                                <MenuItem value={"Durango"}>Durango</MenuItem>
                                <MenuItem value={"Guanajuato"}>Guanajuato</MenuItem>
                                <MenuItem value={"Guerrero"}>Guerrero</MenuItem>
                                <MenuItem value={"Hidalgo"}>Hidalgo</MenuItem>
                                <MenuItem value={"Jalisco"}>Jalisco</MenuItem>
                                <MenuItem value={"Estado de México"}>Estado de México</MenuItem>
                                <MenuItem value={"Michoacán"}>Michoacán</MenuItem>
                                <MenuItem value={"Morelos"}>Morelos</MenuItem>
                                <MenuItem value={"Nayarit"}>Nayarit</MenuItem>
                                <MenuItem value={"Nuevo León"}>Nuevo León</MenuItem>
                                <MenuItem value={"Oaxaca"}>Oaxaca</MenuItem>
                                <MenuItem value={"Puebla"}>Puebla</MenuItem>
                                <MenuItem value={"Querétaro"}>Querétaro</MenuItem>
                                <MenuItem value={"Quintana Roo"}>Quintana Roo</MenuItem>
                                <MenuItem value={"San Luis Potosí"}>San Luis Potosí</MenuItem>
                                <MenuItem value={"Sinaloa"}>Sinaloa</MenuItem>
                                <MenuItem value={"Sonora"}>Sonora</MenuItem>
                                <MenuItem value={"Tabasco"}>Tabasco</MenuItem>
                                <MenuItem value={"Tamaulipas"}>Tamaulipas</MenuItem>
                                <MenuItem value={"Tlaxcala"}>Tlaxcala</MenuItem>
                                <MenuItem value={"Veracruz"}>Veracruz</MenuItem>
                                <MenuItem value={"Yucatán"}>Yucatán</MenuItem>
                                <MenuItem value={"Zacatecas"}>Zacatecas</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Dirección actual"
                            value={student.address || ''}
                            onChange={handleChange('address')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Estado de residencia
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                value={student.livingState || ''}
                                onChange={handleChange('livingState')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"Aguascalientes"}>Aguascalientes</MenuItem>
                                <MenuItem value={"Baja California"}>Baja California</MenuItem>
                                <MenuItem value={"Baja California Sur"}>Baja California Sur</MenuItem>
                                <MenuItem value={"Campeche"}>Campeche</MenuItem>
                                <MenuItem value={"Chiapas"}>Chiapas</MenuItem>
                                <MenuItem value={"Ciudad de México"}>Ciudad de México</MenuItem>
                                <MenuItem value={"Chihuahua"}>Chihuahua</MenuItem>
                                <MenuItem value={"Coahuila"}>Coahuila</MenuItem>
                                <MenuItem value={"Colima"}>Colima</MenuItem>
                                <MenuItem value={"Durango"}>Durango</MenuItem>
                                <MenuItem value={"Guanajuato"}>Guanajuato</MenuItem>
                                <MenuItem value={"Guerrero"}>Guerrero</MenuItem>
                                <MenuItem value={"Hidalgo"}>Hidalgo</MenuItem>
                                <MenuItem value={"Jalisco"}>Jalisco</MenuItem>
                                <MenuItem value={"Estado de México"}>Estado de México</MenuItem>
                                <MenuItem value={"Michoacán"}>Michoacán</MenuItem>
                                <MenuItem value={"Morelos"}>Morelos</MenuItem>
                                <MenuItem value={"Nayarit"}>Nayarit</MenuItem>
                                <MenuItem value={"Nuevo León"}>Nuevo León</MenuItem>
                                <MenuItem value={"Oaxaca"}>Oaxaca</MenuItem>
                                <MenuItem value={"Puebla"}>Puebla</MenuItem>
                                <MenuItem value={"Querétaro"}>Querétaro</MenuItem>
                                <MenuItem value={"Quintana Roo"}>Quintana Roo</MenuItem>
                                <MenuItem value={"San Luis Potosí"}>San Luis Potosí</MenuItem>
                                <MenuItem value={"Sinaloa"}>Sinaloa</MenuItem>
                                <MenuItem value={"Sonora"}>Sonora</MenuItem>
                                <MenuItem value={"Tabasco"}>Tabasco</MenuItem>
                                <MenuItem value={"Tamaulipas"}>Tamaulipas</MenuItem>
                                <MenuItem value={"Tlaxcala"}>Tlaxcala</MenuItem>
                                <MenuItem value={"Veracruz"}>Veracruz</MenuItem>
                                <MenuItem value={"Yucatán"}>Yucatán</MenuItem>
                                <MenuItem value={"Zacatecas"}>Zacatecas</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Género
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={student.gender || ''}
                                onChange={handleChange('gender')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"H"}>Hombre</MenuItem>
                                <MenuItem value={"M"}>Mujer</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Fragment>
                <div style={{margin: '25px'}}>
                    {/*<span style={{margin: '0 25px'}}>*/}
                    {/*<Button variant='contained'*/}
                    {/*        onClick={clearStudentForm}*/}
                    {/*        color="secondary"*/}
                    {/*>*/}
                    {/*    Cancelar*/}
                    {/*</Button>*/}
                    {/*</span>*/}

                    <Button variant='contained'
                            onClick={handleSubmit}
                            color="primary"
                            disabled={!isFormComplete()}
                    >
                        Crear estudiante
                    </Button>
                </div>

            </div>

        );
    }
    return (
        <div className="Students">
            {/*<CustomizedBackdrop open={isBackdrop}/>*/}
            {/*<CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>*/}
            {props.isAuthenticated ? renderInvitation() : renderLander()}
        </div>
    );

};
