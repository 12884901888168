import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    FormControl: {
        width: 550
    }
});

function generateCycles(startYear = 14) {
    const currentYear = new Date().getFullYear() % 100; // Get last two digits of current year
    const endYear = currentYear + 1;
    const cycles = [];

    for (let year = startYear; year <= endYear; year++) {
        for (let quarter = 1; quarter <= 3; quarter++) {
            const value = `${year.toString().padStart(2, '0')}-0${quarter}`;
            cycles.push({value, display: value});
        }
    }

    return cycles;
}

export const MSESelectStartingCycle = (props) => {
    const classes = useStyles();
    const {
        inputLabel,
        label,
        value,
        handleChange,
        disabled,
        nameValue,
        ...others
    } = props;

    const cycles = generateCycles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="startingDate-input">
                Primer Cuatrimestre (requerido)*
            </InputLabel>
            <Select
                labelId={`formcontrol-select-startingdate-${nameValue}`}
                id={`formcontrol-select-startingdate-${nameValue}`}
                value={value || ''}
                onChange={e => handleChange(nameValue, e)}
                required
                className={classes.FormControl}
            >
                {cycles.map((cycle, i) => <MenuItem value={cycle.value} key={`f-s-mi-${nameValue}-${i}`}>{cycle.display}</MenuItem>)}
            </Select>
        </FormControl>
    );
};
